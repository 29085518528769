import React from 'react'
import PropTypes from 'prop-types'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListSubheader from '@mui/material/ListSubheader'
import isEqual from 'lodash/isEqual'
import groupBy from 'lodash/groupBy'

import './switch.css'

const DropDown = ({
	states = [],
	onChange,
	currentState = "",
	title = "",
	description,
	disabled = false,
	// unselected = false,
	name = "status",
	// value = "key",
	// label = "text",
	groupByProperty,
	style = {},
	buttonTextMode = "short",
	multiValue = false,
	nullable = false,
	emptyStateText,
}) => {

	const buttonRef = React.useRef();
	const [open, setOpen] = React.useState(false);

	const onItemClick = React.useCallback(
		(e, value) => {
			onChange({ target: { value, name }}, value);
			if(!multiValue) {
				setOpen(false);
			}
		},
		[onChange]
	);

	const toggleOpen = React.useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();
			setOpen(currentOpen => !currentOpen);
		},
		[setOpen]
	);

	if (!states.length) {
		return null;
	}

	const menuItems = getMenuItems(states, groupByProperty, currentState, onItemClick);

	return (
		<React.Fragment>
			<ButtonElement
				elRef={buttonRef}
				title={title}
				description={description}
				states={states}
				currentState={currentState}
				disabled={disabled}
				name={name}
				buttonTextMode={buttonTextMode}
				emptyStateText={emptyStateText}
				onClick={toggleOpen}
				style={style}
			/>
			{buttonRef?.current && (
				<Menu
					anchorEl={buttonRef?.current}
					open={open}
					onClose={toggleOpen}
					sx={{
						maxHeight: "500px"
					}}
				>
					{menuItems}
				</Menu>
			)}
		</React.Fragment>
	);
};

export default DropDown;

DropDown.propTypes = {
	states: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	currentState: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.arrayOf(PropTypes.number),
		PropTypes.arrayOf(PropTypes.object)
	]),
	title: PropTypes.string,
	disabled: PropTypes.bool,
	// unselected: PropTypes.bool,
	name: PropTypes.string,
	// value: PropTypes.string,
	// label: PropTypes.string,
	groupByProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	anchorOrigin: PropTypes.object,
	targetOrigin: PropTypes.object,
	style: PropTypes.object,
	emptyStateText: PropTypes.string,
	buttonTextMode: PropTypes.oneOf(["short", "long"]),
};

const ButtonElement = (props) => {
	const {
		title,
		description,
		states = [],
		currentState = "",
		disabled,
		name,
		emptyStateText = "None",
		buttonTextMode,
		elRef,
		onClick,
		style = {},
	} = props;

	const buttonText = getButtonText(currentState, states, emptyStateText, buttonTextMode);

	return (
		<div className={`c6-switch ${disabled ? "disabled" : ""}`} ref={elRef} style={style}>
			{title ? <span>{title}</span> : null}
			<label onClick={onClick} title={description}>
				<input
					type="radio"
					name={name}
					checked={true}
					value={true}
					onChange={() => {}}
				/>
				{/* 160 = nbsp, 9662 = arrow down */}
				<span>{`${buttonText} ${String.fromCharCode(160)}${String.fromCharCode(9662)}`}</span>
			</label>
		</div>
	);
}

function getMenuItems(states, groupByProperty, currentState, onItemClick) {
	if (groupByProperty) {
		const grouped = groupBy(states, groupByProperty);
		return Object.keys(grouped).reduce((acc, curr) => (
			[...acc, <ListSubheader key={`group-${curr}`}>{curr}</ListSubheader>, ...getMenuItems(grouped[curr])]
		), []);
	}

	const isChecked = (state) => {
		if (Array.isArray(currentState)) {
			return !!currentState.find(f => isEqual(state.val ?? state.key, f));
		}

		return isEqual(state.val ?? state.key, currentState);
	}

	return states.map(s => {
		const checked = isChecked(s);

		let icon = s.icon;
		if (checked) {
			icon = "check";
		}

		let iconEl = <ListItemIcon></ListItemIcon>;
		if (icon && typeof icon === "string") {
			iconEl = <ListItemIcon><span className={`icon-${icon}`} style={{ color: "var(--action-color)" }}></span></ListItemIcon>;
		} else if (icon) {
			iconEl = icon;
		}

		return (
			<MenuItem
				key={`item-${s.reactKey ?? s.key}`}
				title={s.description}
				disabled={s.disabled}
				onClick={(e) => onItemClick(e, s.val ?? s.key)}
				sx={{
					fontWeight: s.strong ? "bold" : undefined,
					color: checked ? "var(--action-color)" : undefined,
				}}
			>
				{iconEl}
				<span style={{ paddingLeft: s.inset ? "30px" : undefined }}>{s.longText || s.text}</span>
			</MenuItem>
		);
	});
}

function getButtonText(currentState, states, emptyStateText, buttonTextMode) {
	const state = Array.isArray(currentState) ? 
		states.filter(s => !!currentState.find(cs =>  isEqual(s.val, cs))) : 
		states.find(s => isEqual(s.val ?? s.key, currentState));

	if (Array.isArray(state)) {
		if (!state.length)
			return emptyStateText;
		
		if (buttonTextMode === "long") {
			return state.map(s => s.text).join(", ");
		}

		return `${state.length} selected`;
	}

	return state?.text ?? emptyStateText ?? "";
}